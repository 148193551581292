/* Footer styles */
.main-footer {
    margin-top: auto;
    padding: 10px; /* Add padding for spacing */
}

/* Ensure the body and html elements take the full width and height of the viewport */
body {
    margin: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    font-family: -apple-system, BlinkMacSystemFont, 'Lato';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
    background-color: rgb(224 233 240 / 66%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
/*background-image: url('https://static8.depositphotos.com/1010263/1023/i/950/depositphotos_10234143-stock-photo-snow-texture.jpg');*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    background-color: #025b88;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.App-link {
  color: #61dafb;
}
#navbarScrollingDropdown{
    color:aliceblue;
}


@keyframes App-logo-spin {
    from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS */
.autofilled-placeholder {
    /* Add your desired styles here */
    background-color: #cfe2ff6e;
    color: black; /* Change text color to make it visible on the blue background */
}

/* Add this style to the .app-wrapper to ensure it takes up the full height */
.app-wrapper {
    flex: 1;
}


.article{
    padding-top: 70px;
}

