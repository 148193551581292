/* Footer styles */
.main-footer {
    width: 100%;
    color: aliceblue;
    background-color: rgba(13, 110, 253, 0.8); /* Bootstrap primary color with 80% opacity */
    padding: 1rem 0; /* Add padding for better spacing */
}

.sub-footer {
    color: white;
    font-size: 12px;
    font-family: 'Lato';
    margin: 0;
    padding: 0.5rem 0; /* Adjust padding as needed */
    text-decoration: none;
}

    .sub-footer:hover {
        text-decoration: underline;
    }

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem; /* Adjust spacing as needed */
    padding: 0 1rem; /* Add padding to left and right for better alignment */
}

.footer-link {
    margin: 0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .footer-links {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem; /* Adjust spacing for vertical layout */
    }
}
