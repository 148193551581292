/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    color: #333;
    line-height: 1.6;
    text-align: left; /* Ensures body text is left-aligned */
}

.terms-container {
    background: #fff;
    padding: 40px;
    max-width: 900px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* Ensures container text is left-aligned */
}

.terms-header {
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: center; /* Ensures body text is left-aligned */
}

    .terms-header h1 {
        font-size: 2.5em;
        color: #333;
    }

    .terms-header .date {
        font-size: 1em;
        color: #777;
    }

.terms-content h2 {
    font-size: 1.75em;
    color: #2c3e50;
    margin-top: 30px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    text-align: left; /* Ensures h2 text is left-aligned */
}

.terms-content p {
    margin-bottom: 20px;
    font-size: 1em;
    text-align: justify; /* Ensures paragraph text is left-aligned */
}

.terms-footer {
    border-top: 2px solid #eee;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center; /* Ensures footer text is left-aligned */
    color: #777;
}

    .terms-footer p {
        font-size: 0.9em;
    }
