.navbar {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 1000 !important; /* Ensure it's above other content */
    background-color: rgba(13, 110, 253, 0.8) !important; /* Bootstrap primary color with 80% opacity */
    color: #f8f9fa !important;
    font-family: 'Lato' !important;
    min-height: 70px !important;
    transition: top 0.3s;
}

    .navbar.hidden {
        top: -70px !important; /* Adjust this value based on your navbar height */
    }

    .navbar.visible {
        top: 0 !important;
    }
.navbar-nav .nav-link {
    text-decoration: none; /* Remove underline from links */
}
.nav-container{
    color:aliceblue;
}
.d-flex.container{
    padding:0px;
}

.links {
    color: #f8f9fa;
    white-space: nowrap;
}
    .links:hover {
        text-decoration: underline;
        transition: all 0.3s ease; /* Smooth transition for hover effects */
        transform: translateY(-3px); /* Raise the text up */
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }

.vertical-rule-nav {
    border-left: 2px solid rgba(248, 249, 250, 0.5);
}


/* CSS */
.custom-button p:hover {
    text-decoration: underline;
    color: white;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    transform: translateY(-3px); /* Raise the text up */
}
