.scroll-transition {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s, transform 0.5s;
}

    .scroll-transition.active {
        opacity: 1;
        transform: translateY(0);
    }


.button-explore {
    background: transparent;
    border: 1px solid blue;
    color: blue;
    width: 100%;
    height: 100%;
}

    .button-explore:hover {
        background: blue;
        color: white;
    }

.link-discover {
    font-size: 16px;
}

    .link-discover:hover {
        text-decoration: underline; /* Add underline on hover */
        color: blue; /* Change text color to blue on hover */
    }

.vertical-rule {
    border-right: 2px solid rgba(248, 249, 250, 0.5);
}

.bg-image {
    position: relative; /* Required for absolute positioning of pseudo-element */
    background-image: url('https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FLawyerWorking.jpg?alt=media&token=dd39d86d-9fb6-4139-ad08-43d545b0258f');
    background-size: cover;
    background-position: center;
    min-height: 80vh; /* Ensure the background covers the entire viewport height */
    display: flex;
    align-items: flex-end; /* Align items to the bottom */
    padding: 20px; /* Add padding as needed */
    z-index: 0; /* Set a higher z-index */
}

    .bg-image::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); /* Gradient from full black to transparent */
        z-index: -1; /* Ensure the pseudo-element is behind the container */
    }

    .bg-image span {
        font-family: 'Lato'; /* Use your desired font */
        text-transform: uppercase; /* Convert text to uppercase */
        letter-spacing: 2px; /* Adjust letter spacing */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a subtle text shadow */
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-bottom: 20px; /* Add margin bottom */
    }

.forgot-password-link {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove default underline */
}

    .forgot-password-link:hover {
        text-decoration: underline; /* Add underline on hover */
        color: #0056b3; /* Hover color */
    }


/* Add this to your HomepageDesign.css */

@media (max-width: 600px) {
    .responsive-h1 {
        font-size: 12vw; /* Adjust as needed for small screens */
    }

    .responsive-h2 {
        font-size: 4vw;
    }

    .responsive-h3 {
        font-size: 2vw;
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .responsive-h1 {
        font-size: 6vw; /* Adjust as needed for medium screens */
    }

    .responsive-h2 {
        font-size: 1.5vw;
    }

    .responsive-h3 {
        font-size: 1vw;
    }
}

@media (min-width: 1201px) {
    .responsive-h1 {
        font-size: 6vw; /* Adjust as needed for large screens */
    }

    .responsive-h2 {
        font-size: 1.5vw;
    }

    .responsive-h3 {
        font-size: 1vw;
    }
}
