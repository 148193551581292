.vertical-rule {
    border-right: 2px solid grey;
    height: 100%;
}

.scroll-transition {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s, transform 0.5s;
}

   .scroll-transition.active {
        opacity: 1;
        transform: translateY(0);
    }
